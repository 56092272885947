import { Cookies } from 'react-cookie'

const cookies = new Cookies()
const domain = process.env.GATSBY_COOKIES_DOMAIN;
const gatsbyEnv = process.env.GATSBY_ENV;
const isProduction = gatsbyEnv === 'production';

const getEnvCookieName = (name: string) =>
  isProduction ? name : `${gatsbyEnv}_${name}`;

export const getCookie = (name: string): string => {
  return cookies.get(getEnvCookieName(name));
}

export const setCookie = (name: string, value: string, maxAge: number) => {
  const cookieName = getEnvCookieName(name);
  removeCookie(cookieName);
  cookies.set(cookieName, value, { path: '/', maxAge: maxAge, domain });
}

export const removeCookie = (name: string) => {
  const cookieName = getEnvCookieName(name);
  cookies.remove(cookieName, { path: '/' });
  cookies.remove(cookieName, { path: '/', domain });
}
