import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { client } from './client'
import { IntercomProvider } from 'react-use-intercom'

const INTERCOM_APP_ID = 'zumiegqg'

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <IntercomProvider appId={INTERCOM_APP_ID} initializeDelay={10000}>
      {element}
    </IntercomProvider>
  </ApolloProvider>
)
