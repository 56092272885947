/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { setCookie } from './src/services/cookies'

const activateOptimize = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: 'optimize.activate' })
}

const checkUtmParams = () => {
  var urlParams = new URLSearchParams(window.location.search)
  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      window.localStorage.setItem(key, value)
    }
    if (key === 'offer') {
      if (this.isBrowser()) {
        sessionStorage.setItem(key, urlParams[key])
      }
    }
    if (key.toLocaleLowerCase() === 'essentialonepartnership') {
      if (this.isBrowser()) {
        setCookie('essentialOnePartnership', urlParams[key], 14)
      }
    }
    if (key.toLocaleLowerCase() === 'personalisedpluspartnership') {
      if (this.isBrowser()) {
        setCookie('personalisedPlusPartnership', urlParams[key], 14)
      }
    }
  })
}

export { wrapRootElement } from './src/apollo/wrap-root-element'

export const onRouteUpdate = ({ location }) => {
  activateOptimize()
  checkUtmParams()
}
