import { navigate } from 'gatsby'
import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import { getCookie, setCookie } from '../services/cookies'

const getToken = () => {
  const token = getCookie('token')
  return token ? token : ''
}

const setGuestLocale = async () => {
  const cookieExpiry = 30 * 24 * 60 * 60
  const isCurrencySet = getCookie('currency')
  const isUserCountrySet = getCookie('userCountry')

  if (!isCurrencySet || !isUserCountrySet) {
    const response = await fetch(`${process.env.GATSBY_APP_SYNC_URL}`, {
      method: 'POST',
      headers: {
        'x-api-key': `${process.env.GATSBY_APP_SYNC_KEY}`,
        authorization: getCookie('token') || '',
      },
      body: JSON.stringify({
        query: `
        query guest_locale {
          guest_locale {
            currency
            country
          }
        }
      `,
      }),
    })
    const { data } = await response.json()

    if (data) {
      setCookie('currency', data.guest_locale.currency, cookieExpiry)
      setCookie('userCountry', data.guest_locale.country, cookieExpiry)
    } else {
      setCookie('userCountry', 'GB', cookieExpiry)
      setCookie('currency', 'GBP', cookieExpiry)
    }
  }

  return
}

export const client = new ApolloClient({
  uri: process.env.GATSBY_APP_SYNC_URL,
  fetch,
  request: async operation => {
    operation.setContext({
      headers: {
        'x-api-key': process.env.GATSBY_APP_SYNC_KEY,
        authorization: getToken(),
        'x-store-code': await setGuestLocale().then(() =>
          getCookie('currency')
        ),
      },
    })
  },
  onError: ({ networkError, graphQLErrors, response, operation }) => {
    if (graphQLErrors) {
      const error: any = graphQLErrors[0]

      const errorTypesToHandle: Array<String> = [
        'InvalidPassword',
        'PasswordNotMinimumLength',
        'EmailTaken',
        'EmailNotFound',
        'invalidCoupon',
        'InvalidCode',
        'invalidPaymentMethod',
        'invalidBarcode',
        'underAge',
        'invalidBiologicalSex',
        'userKitLimitReached',
        'RegionRestricted',
        'ProductUnavailable',
        'CartProductLimitMet',
        'CartAlreadyOrdered',
        'CartError',
        'CouponRequiresAccount',
        'InvalidCoupon',
        'CouponRequiresHealthProfile',
        'CouponRequiresPersonalisedRecommendation',
        'CouponValidFirstOrderOnly',
        'CouponValidFirstPersonalisedOrderOnly',
        'CannotReferOneself',
        'CouponAlreadyRedeemed',
        'CouponExpired',
        'IncorrectCurrency',
        'CartAlreadyOrdered',
        'EmptyCart',
        'CartPaymentMethodMissing',
        'CartShippingAddressMissing',
        'DefaultPaymentMethodNotRemovable',
      ]

      if (error.errorType === 'Unauthorized') {
        navigate('/account/signin')
      } else if (errorTypesToHandle.includes(error.errorType)) {
        console.warn('GraphQL error: ', error)
      } else {
        navigate('/error')
        console.warn('GraphQL error: ', error)
      }
    }

    if (networkError) {
      console.warn('networkError', networkError)
    }
  },
})
